import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import LinearProgress from 'material-ui/LinearProgress';
import { authLogout, setAgencySuccess } from '@actions/auth-actions';
import { fetchDataTypes } from '@actions/data-types-actions';
import { pollUnreadNotificationsAsync } from '@actions/notifications-actions';
import { fetchConfigUpdate } from '@components/config/actions';
import { isNewLayout } from '@constants/config';
import muiTheme, { paper } from '@constants/mui-theme';
import { dataTypesLoaderSelector } from '@selectors/data-types-selector';
import OldHeader from '@shared/header';
import Header from '@components/header';
import { getAgencyId } from '@utils/permission-utils';
import { withRouter } from '@utils/router-utils';
import ActionConfirmationDialog from './confirmation-dialog';
import ErrorSnackbar from './error-snackbar';
import InfoSnackbar from './info-snackbar';
import IdleMonitor from './idle-monitor';
import AgencySelect from './agency-select';
import WarningDialog from './warning-dialog';
import './authenticated.scss';

class AuthenticatedLayout extends IdleMonitor {
  componentDidMount() {
    super.componentDidMount();  // Call IdleMonitor's method.
    this.props.fetchConfigUpdate();
    this.props.fetchDataTypes();
    this.props.setAgencySuccess(getAgencyId());
    window.stopPolling = false;
    this.props.pollUnreadNotificationsAsync();
  }
  componentWillUnmount() {
    super.componentWillUnmount();
    window.stopPolling = true;
  }
  onIdle = () => {
    // Logout the user if idle:
    this.props.authLogout(this.props.location);
  };
  render() {
    const { count, total } = this.props.loader;
    const loaded = count === total;
    return (
      <MuiThemeProvider muiTheme={muiTheme}>
        <div styleName="app-container">
          <div
            styleName="header-container"
            style={{
              ...paper,
              boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px'
            }}
          >
            {isNewLayout() && <Header />}
            {!isNewLayout() && <OldHeader />}
          </div>
          <div styleName={isNewLayout() ? 'content-container' : 'content-container-old'}>
            {loaded
              ? this.props.children
              : <LinearProgress mode="determinate" value={count} max={total} />}
          </div>
          <InfoSnackbar />
          <ErrorSnackbar />
          <ActionConfirmationDialog />
          <WarningDialog />
          <AgencySelect />
        </div>
      </MuiThemeProvider>
    );
  }
}

AuthenticatedLayout.propTypes = {
  authLogout: PropTypes.func,
  children: PropTypes.element,
  fetchConfigUpdate: PropTypes.func,
  fetchDataTypes: PropTypes.func,
  loader: PropTypes.object,
  location: PropTypes.object,
  pollUnreadNotificationsAsync: PropTypes.func
};

const mapStateToProps = state => ({ loader: dataTypesLoaderSelector(state)});

const mapDispatchToProps = {
  authLogout,
  fetchConfigUpdate,
  fetchDataTypes,
  pollUnreadNotificationsAsync,
  setAgencySuccess
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AuthenticatedLayout));

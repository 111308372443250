import { isEmpty } from 'lodash';
import * as R from 'ramda';
import moment from 'moment-timezone';

export const dateToString = date => {
  if (typeof date === typeof true) {  // A future-safe way to check if a variable is a bool.
    return date;
  }
  const newDate = moment(date);
  if (newDate.isValid()) {
    return newDate.format('YYYY-MM-DD');
  }
  return date;
};

const datesToString = object => R.map(dateToString, object);

const getDateFilterValues = ({ start, end, contain, undated, undatedOnly }) => {
  const hidden = {
    hidden_start: start,  // Save start/end for when we disabled 'undatedOnly'.
    hidden_end: end,
    hidden_contain: contain
  };
  if (undatedOnly) {
    return {
      ...hidden,
      start_date__isnull: true,
      end_date__isnull: true,
      undated: true,
      undatedOnly: true
    };
  }
  if (undated) {
    return {
      ...hidden,
      start_date__lte__isnull: contain ? null : end,
      end_date__lte__isnull: contain ? end : null,
      start_date__gte__isnull: contain ? start : null,
      end_date__gte__isnull: contain ? null : start,
      undated: true  // This tells the reducer to clear the non-isnull parameters
    };
  }
  return {
    ...hidden,
    start_date__lte: contain ? null : end,
    end_date__lte: contain ? end : null,
    start_date__gte: contain ? start : null,
    end_date__gte: contain ? null : start
  };
};

export const generateDateFilters = state => datesToString(getDateFilterValues(state));

// Decode filters from Redux state into { start, end, contain } properties
export const decodeDateFilters = filters => {
  const {
    start_date__lte = null,
    end_date__lte = null,
    start_date__gte = null,
    end_date__gte = null,
    start_date__lte__isnull = null,
    end_date__lte__isnull = null,
    start_date__gte__isnull = null,
    end_date__gte__isnull = null,
    start_date__isnull = null,
    hidden_start = null,
    hidden_end = null,
    hidden_contain = null
  } = filters || {};

  // Use lodash's isEmpty() instead of the Ramda one, since the later doesn't return true
  // if the variable is undefined.
  const contain = (end_date__lte || start_date__gte) && isEmpty(start_date__lte) && isEmpty(end_date__gte);
  const start = contain ? start_date__gte : end_date__gte;
  const end = contain ? end_date__lte : start_date__lte;

  const contain__isnull = (end_date__lte__isnull || start_date__gte__isnull) && isEmpty(start_date__lte__isnull) && isEmpty(end_date__gte__isnull);
  const start__isnull = contain__isnull ? start_date__gte__isnull : end_date__gte__isnull;
  const end__isnull = contain__isnull ? end_date__lte__isnull : start_date__lte__isnull;

  if (start_date__isnull) {
    return { start: hidden_start, end: hidden_end, contain: hidden_contain, undated: true, undatedOnly: true };
  }

  if (start__isnull) {
    return { start: start__isnull, end: end__isnull, contain: contain__isnull, undated: true, undatedOnly: false };
  }

  return { start, end, contain, undated: false, undatedOnly: false };
};

const getSingleDateFilterValues = ({ start, end }, fieldName) => ({
  [`${fieldName}__gte`]: start,
  [`${fieldName}__lte`]: end
});

export const generateSingleDateFilters = (state, fieldName) => datesToString(getSingleDateFilterValues(state, fieldName));

export const decodeSingleDateFilters = (filters, fieldName) => {
  let start = null;
  let end = null;
  if (filters) {
    start = filters[`${fieldName}__gte`] || null;
    end = filters[`${fieldName}__lte`] || null;
  }
  return { start, end, contain: false };
};

export const selectedDateFilterValue = (start, end) => {
  if (start === null && end === null) {
    return 'any'; // Any time:
  }

  const startDate = moment(start, 'YYYY-MM-DD');
  const endDate = moment(end, 'YYYY-MM-DD');

  if (startDate.isSame(endDate, 'day')) {
    if (startDate.isSame(moment(), 'day')) {
      return 'today';
    }
    if (startDate.isSame(moment().add(1, 'days'), 'day')) {
      return 'tomorrow';
    }
  }

  if (startDate.isSame(moment(), 'day')) {
    if (endDate.isSame(moment().add(7, 'days'), 'day')) {
      return 'next7';
    }
    if (endDate.isSame(moment().add(30, 'days'), 'day')) {
      return 'next30';
    }
    if (endDate.isSame(moment().add(90, 'days'), 'day')) {
      return 'next90';
    }
    if (endDate.isSame(moment().add(365, 'days'), 'day')) {
      return 'nextYear';
    }
    if (endDate.isSame(moment().add(1095, 'days'), 'day')) {
      return 'next3Years';
    }
  }
  // Else it's a custom range date:
  return 'custom';
};


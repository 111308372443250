import React from 'react';
import * as R from 'ramda';
import { capitalize, isString } from 'lodash';
import { Link } from 'react-router-dom';
import { getDashboardMenuConfig } from '@constants/config';
import { topBarStyles } from '@constants/mui-theme';
import { isNumeric } from '@utils/data-detail-utils';
import { getCookies } from '@utils/shared-utils';

const getDefaultFromCookies = key => {
  const value = getCookies().get(key);

  return isNumeric(value) ? parseInt(value, 10) : value;
};

const getDefaultValue = field => {
  const { defaultValue } = field;
  if (defaultValue) {
    if (!isString(defaultValue)) {
      return getDefaultFromCookies(defaultValue.key);
    }
  }
  return defaultValue;
};

// Return a pair with the fields key and the default id:
const toPair = fields => [fields.key, getDefaultValue(fields)];

// Used to return the initial values for a dashboard data type:
export const getInitialValues = data => {
  const { sections } = data;
  const fields = sections[0].fields;
  return R.fromPairs(R.map(toPair, R.values(R.pickBy(field => field.defaultValue, fields))));
};

export const getFieldValue = (field, select, defaultValue, data, dataTypes) => {
  const path = field.split('.');
  let value = `${R.pathOr('', path, data)}`;
  if (!value && defaultValue) {
    if (isString(defaultValue)) {
      value = defaultValue;
    } else {
      value = getDefaultFromCookies(defaultValue.key);
    }
  }
  if (select) {
    return `${R.pathOr('', [select.type, value, select.key], dataTypes)}`;
  }
  return value;
};

export const isMenuSectionActive = (section, type) =>
  section.items.find(item => item.type === type || item.subtype === type);

export const parseNullFields = dict =>
  dict && R.map(value => (value || value === 0 || value === false ? value : ''), dict);

export const parseEmptyStrings = dict =>
  dict && R.map(value => (value === '' ? null : value), dict);

// Based on a data type, renders the data type label, taking it from the
// dashboard menu configuration.
export const labeizeDataType = (dataType, subType = null) => {
  const menu = getDashboardMenuConfig();
  const items = R.map(item => item.items, menu);
  const flatItems = [].concat(...items);

  let menuItem = null;
  if (subType && dataType !== subType) {
    menuItem = R.find(R.propEq(subType, 'subtype'))(flatItems);
  } else {
    menuItem = R.find(R.propEq(dataType, 'type'))(flatItems);
  }
  if (menuItem) {
    return menuItem.label;
  }

  return capitalize(dataType);
};

export const renderBreadCrumbDataType = (dataType, subType, hasDetail) => {
  const label = labeizeDataType(dataType, subType);

  if (hasDetail && dataType !== 'overlaps') {
    return (
      <Link to={`/list/data/${dataType}`}
        style={topBarStyles.breadCrumb.link}>
        {label}
      </Link>
    );
  }

  return label;
};

import React from 'react';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import transitions from 'material-ui/styles/transitions';
import hexToRgba from 'hex-rgba';
import * as colors from '@constants/colors';
// We got to use a custom icon for the dropdown caret to be darker,
// because the DropDownMenu component (which SelectField uses), doesn't
// have a way to customize the iconButton's colors.
import DropDownArrowIcon from '@icons/arrow-drop-down-icon';

export const muiThemePalette = {
  textColor: colors.dotmapsGray,
  primary1Color: colors.dotmapsBlue,
  primary2Color: colors.dotmapsLightBlue
};

const customMuiTheme = getMuiTheme({ palette: muiThemePalette });

const muiTheme = {
  ...customMuiTheme,
  chip: {
    ...customMuiTheme.chip,
    deleteIconColor: 'rgba(0, 0, 0, 0.26)'
  },
  menuItem: {
    ...customMuiTheme.menuItem,
    selectedTextColor: colors.dotmapsBlue
  },
  textField: {
    ...customMuiTheme.textField,
    textColor: colors.dotmapsBlack80,
    floatingLabelColor: colors.dotmapsBlack60,
    disabledTextColor: colors.dotmapsBlack40,
    errorColor: colors.dotmapsError
  },
  snackbar: {
    ...customMuiTheme.snackbar,
    actionColor: colors.dotmapsSnackbarAction
  },
  tooltip: {
    ...customMuiTheme.tooltip,
    rippleBackgroundColor: colors.dotmapsBlack60
  }
};

// Style guide:
export const defaultInputStyle = {
  fontSize: '0.875rem'
};

const defaultUnderlineDisabledStyle = {
  borderBottomWidth: '1px',
  borderColor: colors.dotmapsBlack20
};

const defaultUnderlineStyle = {
  borderColor: colors.dotmapsBlack40
};

const underlineStyles = {
  underlineDisabledStyle: defaultUnderlineDisabledStyle,
  underlineStyle: defaultUnderlineStyle
};

const inputStyles = {
  inputStyle: defaultInputStyle,
  floatingLabelStyle: {
    fontSize: '0.875rem'
  }
};

const defaultStyles = {
  ...underlineStyles,
  ...inputStyles
};

const defaultFloatingLabelStyle = {
  transform: 'scale(0.85) translate(0, -28px)',
  pointerEvents: 'none'
};

export const styleGuide = {
  select: {
    ...defaultStyles,
    // With 'fullWidth' we make the dropdown popover to match
    // the TextField's width.
    fullWidth: true,
    dropDownMenuProps: {
      iconButton: <span className="select-dropdown-icon"><DropDownArrowIcon color={colors.dotmapsGrayInactive} /></span>,
      menuStyle: {
        // Related to the 'fullWidth' key above, of the dropdown menu
        // contents exceeds the TextField's width, they make
        // scrollbars appear, with 'max-content' we above that,
        // so the dropdown always either matches the TextField's
        // width or exceeds it if it has longer items.
        //
        // Another way to do this is to use 'autoWidth',
        // but that causes the DropDownMenu component
        // (called within SelectField) to calculate
        // the menu's width, which can smaller
        // then the TextField if the items are too
        // short in width, thus the best is to use
        // this 'max-content' trick.
        minWidth: 'max-content'
      }
    },
    iconStyle: {
      padding: 0,
      width: 'auto'
    },
    labelStyle: defaultInputStyle,
    floatingLabelShrinkStyle: { ...defaultFloatingLabelStyle }
  },
  textField: {
    ...defaultStyles,
    textareaStyle: defaultInputStyle,
    floatingLabelShrinkStyle: { ...defaultFloatingLabelStyle }
  }
};

export const disabledFormField = {
  ...styleGuide.select.labelStyle,
  display: 'flex',
  alignItems: 'center',
  fontFamily: 'Roboto',
  lineHeight: '3rem',
  whiteSpace: 'noWrap',
  margin: '0 0.5rem',
  width: '100%'
};

const formLabelStyle = {
  alignItems: 'center',
  display: 'flex',
  fontFamily: 'Roboto',
  fontSize: '14px',
  lineHeight: '1.29',
  color: colors.dotmapsBlack80,
  width: 'auto'
};

export const formStyles = (readOnly = false) => ({
  checkbox: {
    iconStyle: {
      height: '1.125rem',
      marginRight: '0.5rem',
      width: '1.125rem'
    },
    labelStyle: {
      ...formLabelStyle,
      color: !readOnly ? formLabelStyle.color : 'rgba(0,0,0,0.3)'
    },
    style: {
      alignItems: 'center',
      display: 'flex',
      paddingLeft: '1.5rem',
      width: 'auto'
    }
  },
  radioGroup: {
    style: {
      display: 'flex'
    }
  },
  radio: {
    iconStyle: {
      height: '1.0625rem',
      marginRight: '0.5rem',
      width: '1.0625rem'
    },
    inputStyle: {
    },
    labelStyle: {
      ...formLabelStyle,
      lineHeight: 'auto',
      paddingRight: '1.5rem',
      width: 'auto',
      color: !readOnly ? formLabelStyle.color : 'rgba(0,0,0,0.3)'
    },
    style: {
      alignItems: 'center',
      display: 'flex',
      width: 'auto'
    }
  },
  toggle: {
    labelStyle: {
      ...formLabelStyle,
      color: !readOnly ? formLabelStyle.color : 'rgba(0,0,0,0.3)'
    },
    style: {
      width: 'auto'
    }
  }
});

const ellipsisText = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
};

export const ganttBarIconButtonStyles = {
  height: '2rem',
  padding: '0.5rem 1.5rem 0.5rem 1.5rem',
  width: '5rem'
};

export const dataTableEmptyResultsIconStyles = {
  color: colors.dotmapsGrayEmptyIconV2,
  style: {
    height: '56px',
    width: '56px'
  },
  viewBox: '0 0 56 56'
};

export const readOnlyText = {
  color: 'rgba(0, 0, 0, 0.3)',
  cursor: 'not-allowed'
};

export const labelStyles = {
  new: {
    color: '#3D82FF',
    fontWeight: 'bold',
    textAlign: 'left',
    textTransform: 'uppercase'
  },
  caution: {
    color: 'red'
  }
};

export const actionButton = {
  backgroundColor: colors.dotmapsLighterBlue,
  hoverColor: colors.dotmapsLighterBlue,
  rippleColor: colors.dotmapsDarkBlue,
  style: {
    color: 'white',
    margin: '0 0 0 1em'
  }
};

export const actionButtonDisabled = {
  backgroundColor: colors.dotmapsLightGrayInactive,
  style: {
    color: 'white',
    margin: '0 0 0 1em'
  }
};

export const loginStyles = {
  links: {
    color: '#717171',
    textDecoration: 'none'
  },
  successLinks: {
    color: '#3E73E7',
    textDecoration: 'none'
  },
  buttonLabel: {}
};

export const topBarStyles = {
  breadCrumb: {
    link: {
      color: colors.dotmapsInactiveBlue,
      textDecoration: 'none'
    },
    separator: {
      fontSize: '1.7em',
      paddingTop: '0.1em'
    }
  },
  details: {
    actionButton: {
      textTransform: 'uppercase'
    }
  },
  editLinks: {
    color: colors.dotmapsBlack80
  },
  links: {
    color: colors.overlayTextColor
  },
  loader: {
    display: 'inline-block',
    height: '30px',
    position: 'relative',
    width: '30px'
  },
  loaderIconWrapper: {
    alignItems: 'center',
    display: 'inline-flex',
    height: '48px',
    justifyContent: 'center',
    position: 'relative',
    width: '48px'
  },
  toolbar: {
    backgroundColor: '#FFFFFF',
    height: '100%',
    padding: 0
  },
  toolbarSeparator: {
    left: {
      height: '100%',
      marginLeft: '1em',
      marginRight: '1em'
    },
    right: {
      height: '100%',
      marginLeft: '1em',
      marginRight: '1em'
    }
  },
  pngIcons: {
    size: 18
  },
  waffleIcons: {
    size: 32
  },
  oldIcons: {
    color: '#7A7A7A'
  },
  icons: {
    color: '#616161'
  },
  addIcon: {
    backgroundColor: '#406EE3',
    borderRadius: '50%',
    margin: '0 0.5rem 0 1rem',
    height: '2rem',
    width: '2rem',
    padding: '1px 4px'
  }
};

const avatarStyles = {
  marginRight: '0.5rem',
  verticalAlign: 'middle',
  backgroundColor: 'white'
};

const commonDialogField = {
  clear: 'both',
  marginTop: '2em'
};

export const detailStyles = {
  chip: {
    backgroundColor: colors.dotmapsBlue,
    display: 'inline-block'
  },
  chipLabelColor: 'white',
  chipLabelStyle: {
    fontSize: '1em',
    fontWeight: 'bold',
    lineHeight: '1.5em',
    paddingLeft: '0.5em',
    paddingRight: '0.5em'
  },
  dialog: {
    edit: {
      status: {
        checkbox: {
          iconStyle: {
            ...formStyles().checkbox.iconStyle
          },
          labelStyle: {
            ...formStyles().checkbox.labelStyle
          },
          style: {
            ...formStyles().checkbox.style,
            paddingLeft: '0'
          }
        }
      }
    },
    group: commonDialogField,
    groupStatus: {
      ...commonDialogField,
      width: '10em'
    },
    status: commonDialogField
  },
  item: {
    avatar: avatarStyles
  },
  list: {
    padding: 0
  },
  listItem: {
    margin: 0,
    padding: 0
  },
  groupOpenListItem: {
    // eslint-disable-next-line max-len
    boxShadow: `0 1px 18px 0 ${colors.dotmapsBlack12}, 0 6px 10px 0 ${colors.dotmapsBlack14}, 0 3px 5px -1px ${colors.dotmapsBlack20}`,
    borderLeft: `4px solid ${colors.secondaryColor}`,
    margin: '0 0 1rem 0',
    maxHeight: '10000px',
    overflow: 'hidden',
    transition: 'max-height 0.25s ease-in-out'
  },
  groupClosedListItem: {
    maxHeight: '50px'
  },
  conflictListItem: {
    innerDivStyle: {
      padding: '1rem 1rem 0 1rem'
    },
    style: {
      borderBottom: `1px solid ${colors.dotmapsGrayBorder}`
    }
  },
  selectedItem: {
    borderLeft: `4px solid ${colors.secondaryColor}`
  },
  status: {
    common: {
      labelColor: '#FFFFFF',
      labelStyle: {
        textTransform: 'uppercase'
      },
      style: {
        height: 26
      }
    },
    blue: {
      backgroundColor: '#2093FF'
    },
    green: {
      backgroundColor: '#20C05C'
    },
    red: {
      backgroundColor: '#FF4744'
    },
    white: {
      backgroundColor: '#FFFFFF',
      labelColor: '#2093FF',
      buttonStyle: {
        border: '1px solid #2093FF'
      }
    },
    yellow: {
      backgroundColor: '#FBBA10'
    }
  },
  conflictStatus: {
    common: {
      style: {
        height: '22px',
        lineHeight: '20px',
        minWidth: '75px'
      },
      labelStyle: {
        color: '#FFFFFF',
        fontSize: '.8em',
        textTransform: 'capitalize'
      }
    },
    blue: {
      backgroundColor: '#2093FF'
    },
    green: {
      backgroundColor: '#20C05C'
    },
    red: {
      backgroundColor: '#FF4744'
    },
    white: {
      backgroundColor: '#FFFFFF',
      labelStyle: { color: '#2093FF', fontSize: '.8em', textTransform: 'capitalize' },
      style: { border: 'solid 1px #2093FF', height: '22px', lineHeight: '20px', minWidth: '75px' }
    },
    yellow: {
      backgroundColor: '#FBBA10'
    }
  },
  conflictMainStatus: {
    green: {
      color: '#20C05C'
    },
    red: {
      color: '#FF4744'
    },
    yellow: {
      color: '#FBBA10'
    }
  },
  forms: {
    ...formStyles()
  },
  segments: {
    ...formStyles()
  }
};

export const spinnerStyles = {
  height: '6rem',
  marginTop: '3rem',
  position: 'relative'
};

// A select dropdown which is the same as the system
// one we use (i.e. the same as '...styleGuide.select'),
// however the floatingLabel, doesn't move when we click
// on the caret (i.e. 'transform' is disabled), and the
// height of the whole input box must be the same as
// for when we don't have a floating label.
const sameSizeSelect = {
  ...styleGuide.select,
  floatingLabelShrinkStyle: {
    transform: 'none'
  },
  floatingLabelStyle: {
    ...styleGuide.select.floatingLabelStyle,
    lineHeight: 'auto',
    top: 0
  },
  iconStyle: {
    top: 0
  },
  labelStyle: {
    ...styleGuide.select.labelStyle,
    height: '3rem',
    lineHeight: '3rem',
    top: '-4px'
  },
  menuStyle: {
    margin: 0
  },
  style: {
    height: '3rem',
    lineHeight: '3rem',
    margin: '1rem 0 0 0'
  }
};

// Dialog styles:
export const dialogStyles = {
  content: {
    title: {
      padding: '1.5rem 1.5rem 1rem 1.5rem',
      fontFamily: 'Roboto',
      fontSize: '1.375rem',  // 22px
      fontWeight: 'normal',
      lineHeight: '1.27',
      color: colors.dotmapsBlack80
    },
    body: {
      padding: '0 1.5rem'
    },
    actions: {
      padding: '1rem'
    }
  },
  buttons: {
    attention: {
      backgroundColor: colors.dotmapsAttentionAction,
      color: colors.overlayTextColor,
      marginLeft: '1em'
    },
    caution: {
      color: '#EF5552',
      float: 'left'
    },
    primary: {
      marginLeft: '1em'
    }
  },
  form: {
    item: {
      paddingTop: '2em'
    }
  },
  item: {
    avatar: {
      ...avatarStyles,
      display: 'inline-block'
    },
    avatarLessCheckbox: {
      top: '0.2em'
    },
    checkbox: {
      top: '0.5em'
    },
    listItem: {
      display: 'inline-block',
      width: '50%'
    },
    listItemInner: {
      padding: '0.5em 1em 0.5em 3em'
    }
  },
  select: {
    ...sameSizeSelect
  },
  largeDialog: {
    maxWidth: '42.5em'
  },
  mediumDialog: {
    maxWidth: '32em'
  },
  smallDialog: {
    maxWidth: '26em'
  },
  actionTitleStyle: {
    fontFamily: 'Roboto',
    fontSize: '1.375rem',
    color: colors.dotmapsBlack80
  },
  title: {
    fontFamily: 'Roboto',
    fontSize: '1.375rem',
    fontWeight: '400',
    color: colors.dotmapsBlack80
  }
};

export const mapTrayStyles = {
  tabs: {
    tabItemContainerStyle: {
      background: colors.overlayTextColor,
      flexShrink: 0,
      boxShadow: 'inset 0 -1px 0 0 #EBEBEB'
    },
    inkBarStyle: {
      background: colors.secondaryColor
    },
    contentContainerClassName: 'map-tray-tabs-content-container',
    contentContainerStyle: {
      overflowY: 'auto',
      height: '100%',
      flexGrow: 1,
      flexDirection: 'column'
    },
    style: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      height: '100%'
    },
    tabTemplateStyle: {
      position: 'static',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1
    }
  },
  tab: {
    buttonStyle: {
      height: '3em',
      color: colors.dotmapsBlack40
    }
  },
  activeTab: {
    buttonStyle: {
      height: '3em',
      color: colors.secondaryColor
    }
  },
  button: {
    style: {
      minWidth: '2em'
    },
    buttonStyle: {
      background: colors.googleMapsHoverBlue
    }
  }
};

export const dashboardDetails = {
  input: {
    fullWidth: true,
    underlineStyle: {
      borderColor: colors.dotmapsGray
    }
  },
  select: {
    iconStyle: {
      fill: colors.dotmapsGray
    }
  }
};

// Detail editing styles
const segmentEditFontSizeEM = 1;
const segmentEditButtonSizeEM = 1.5;

export const overlapButton = {
  style: {
    border: '1px solid #EBEBEB',
    borderRadius: '2px'
  }
};

export const detailEdit = {
  check: {
    iconStyle: {
      height: '1.125rem',
      marginRight: '0.5rem',
      width: '1.125rem'
    },
    labelStyle: {
      lineHeight: '1.0625rem',
      width: 'auto'
    },
    style: {
      marginTop: '1em',
      paddingBottom: '0.5rem'
    }
  },
  chip: {
    deleteIconStyle: {
      width: '20px'
    },
    labelStyle: {
      fontFamily: 'Roboto',
      fontSize: '0.875rem'
    }
  },
  errors: {
    fontSize: '0.75rem',
    lineHeight: '0.75rem'
  },
  toggle: {
    style: {
      marginTop: '1em',
      paddingBottom: '0.5rem'
    }
  },
  textField: {
    ...styleGuide.textField,
    inputStyle: {
      ...styleGuide.textField.inputStyle,
      width: 'calc(100% - 1px)'
    }
  },
  segmentEdit: {
    addButton: {
      style: {
        margin: '0.375em'
      }
    },
    textField: {
      ...styleGuide.textField,
      inputStyle: {
        ...styleGuide.textField.inputStyle,
        width: '100%'
      },
      style: {
        width: '100%'
      }
    },
    openScheduleButton: {
      style: {
        cursor: 'pointer',
        fontSize: '1.125rem',  // 18px
        opacity: '0.6'
      }
    },
    card: {
      fontSize: `${segmentEditFontSizeEM}em`,
      lineHeight: `${segmentEditFontSizeEM}em`,
      padding: 0
    },
    cardContents: {
      padding: 0
    },
    check: {
      iconStyle: {
        height: '1.125rem',
        marginRight: '0.5rem',
        width: '1.125rem'
      }
    },
    delete: {
      float: 'right',
      position: 'relative',
      marginTop: `${segmentEditFontSizeEM - segmentEditButtonSizeEM}em`,
      top: `${-(segmentEditFontSizeEM - segmentEditButtonSizeEM) / 2}em`,
      height: `${segmentEditButtonSizeEM}em`,
      width: `${segmentEditButtonSizeEM}em`
    },
    dateRange: {
      width: '8.5rem',
      marginRight: '1rem',
      display: 'inline-block'
    },
    timeRange: {
      width: '7.5rem',
      marginRight: '1rem',
      display: 'inline-block'
    },
    radioGroup: {
      style: {
        display: 'flex',
        paddingBottom: '0.3rem',
        paddingTop: '1rem'
      }
    },
    radio: {
      ...detailStyles.segments.radio,
      labelStyle: {
        ...detailStyles.segments.radio.labelStyle,
        fontFamily: 'Roboto',
        fontSize: '14px',
        lineHeight: '1.29',
        color: colors.dotmapsBlack80,
        paddingRight: '3rem'
      }
    }
  },
  columnStyles: {
    hide: {
      display: 'none'
    },
    col25: {
      width: 'calc(25% - 2.5em)',
      marginLeft: '2em'
    },
    col33: {
      width: 'calc(33% - 2.55em)',
      marginLeft: '2em'
    },
    col50: {
      width: 'calc(50% - 3em)',
      marginLeft: '2em',
      verticalAlign: 'top',
      whiteSpace: 'nowrap'
    },
    col75: {
      width: 'calc(75% - 3.5em)',
      marginLeft: '2em'
    },
    col100: {
      width: 'calc(100% - 4em)',
      marginLeft: '2em'
    },
    full: {
      width: '100%'
    },
    insideCol50Left: {
      width: 'calc(50% - 0.5em)'
    },
    insideCol50Right: {
      width: 'calc(50% - 0.5em)',
      marginLeft: '1em'
    },
    toggleError: {
      color: colors.dotmapsBlack60,
      fontSize: '0.875em',
      margin: '0.5rem 0 0 0'
    },
    startDatetime: {
      display: 'inline'
    },
    endDatetime: {
      display: 'inline'
    },
    fullDatetime: {
      display: 'block',
      width: 'calc(100% - 4em)',
      marginLeft: '2em'
    },
    startDateRangeDate: {
      width: 'calc(30% - 2em)',
      marginLeft: '2em',
      display: 'inline-block',
      verticalAlign: 'top'
    },
    startDateRangeTime: {
      width: 'calc(20% - 2em)',
      marginLeft: '1em',
      display: 'inline-block',
      verticalAlign: 'top'
    },
    endDateRangeDate: {
      width: 'calc(30% - 2em)',
      marginLeft: '1em',
      display: 'inline-block',
      verticalAlign: 'top'
    },
    endDateRangeTime: {
      width: 'calc(20% - 2em)',
      marginLeft: '2em',
      display: 'inline-block',
      verticalAlign: 'top'
    },
    DateTimeRange: {
      width: 'calc(50% - 3em)',
      display: 'inline-block',
      marginLeft: '2em',
      verticalAlign: 'top',
      whiteSpace: 'nowrap'
    }
  }
};

export const uploadStyle = {
  uploadIconButton: {
    color: colors.secondaryColor
  }
};

export const calendarStyles = {
  event: color => ({
    backgroundColor: hexToRgba(color, 55),
    border: `1px solid ${color}`,
    height: 22,
    borderRadius: 1,
    fontSize: 12,
    fontWeight: 400,
    color: colors.dotmapsGray
  })
};

export const commentSortSelect = {
  ...sameSizeSelect,
  style: {
    height: 'auto',
    maxWidth: '7.875rem',
    fontSize: '0.875rem',
    fontFamily: 'Roboto',
    ...ellipsisText
  },
  labelStyle: {
    padding: '0 2.25rem 0 0'
  },
  iconStyle: {
    padding: '0', // Mess with this to get the button centered.
    width: 'auto'
  },
  underlineStyle: {
    border: 'none'
  },
  maxHeight: 400
};

export const addToNewGroupStyles = {
  button: {
    color: colors.dotmapsBlue
  }
};

export const listItemStyles = {
  filterButtonStyle: {
    padding: '0',
    height: 'auto',
    width: 'auto'
  },
  locationTypeButtonStyle: {
    padding: '0 0.25rem 0 0',
    height: 'auto',
    width: 'auto'
  }
};

const filterRadio = {
  iconStyle: {
    height: '1.0625rem',
    marginRight: '0.5rem',
    width: '1.0625rem'
  },
  inputStyle: {
  },
  labelStyle: {
    lineHeight: 'auto',
    width: 'auto'
  },
  style: {
    alignItems: 'center',
    display: 'flex',
    padding: '0.5rem',
    width: '100%'
  }
};

export const layersMenuStyles = {
  radioGroup: {
    style: {
      width: '100%'
    }
  },
  radio: {
    inputStyle: {
    },
    labelStyle: {
      lineHeight: 'auto',
      width: '100%'
    },
    style: {
      alignItems: 'center',
      display: 'flex',
      padding: '0.5rem 0',
      width: '100%'
    }
  },
  menuIcons: {
    height: '1.375rem',
    width: '1.375rem',
    borderRadius: '0.25rem',
    border: '1px solid #DDD',
    marginRight: '0.5rem'
  },
  buttonIcons: {
    height: '1.375rem',
    width: '1.375rem',
    borderRadius: '0.25rem',
    border: '1px solid #999'
  }
};

export const fileUploadStyles = {
  radio: {
    ...filterRadio,
    style: {
      ...filterRadio.style,
      padding: '0'
    }
  }
};

export const tooltipStyles = {
  backgroundColor: colors.dotmapsBlack60,
  borderRadius: '2px',
  color: colors.overlayTextColor,
  fontFamily: 'Roboto, sans-serif',
  fontSize: '0.75rem',  // 12px
  lineHeight: '1.33',
  padding: '0.25rem 0.5rem',
  pointerEvents: 'none'
};

export const moreListTooltipStyles = {
  avatarStyle: {
    border: `1px solid ${colors.dotmapsGrayBorder}`,
    fontFamily: 'Roboto, sans-serif'
  },
  seeDetailsStyle: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '0.75rem',
    fontStyle: 'italic',
    lineHeight: '1.33',
    color: colors.dotmapsBlack40,
    padding: '0.6875rem 1rem'
  },
  itemStyle: {
    padding: '0.6875rem 1rem'
  },
  tooltip: {
    backgroundColor: colors.overlayTextColor,
    borderRadius: '2px',
    color: colors.dotmapsBlack80,
    fontFamily: 'Roboto, sans-serif',
    fontSize: '0.875rem',
    padding: '0.5rem 0',
    pointerEvents: 'none'
  }
};

export const filterStyles = {
  dashboardCheck: {
    ...formStyles().checkbox
  },
  check: {
    iconStyle: {
      height: '1.125rem',
      marginRight: '0.5rem',
      width: '1.125rem'
    },
    labelStyle: {
      lineHeight: '1.0625rem',
      width: 'auto'
    }
  },
  radioGroup: {
    style: {
      display: 'flex',
      flexWrap: 'wrap'
    }
  },
  radio: {
    ...filterRadio
  },
  toggle: {
    iconStyle: {
      margin: '0'
    }
  },
  filterGroupRadio: {
    ...filterRadio,
    style: {
      ...filterRadio.style,
      padding: '0'
    }
  },
  dateRadio: {
    ...filterRadio,
    iconStyle: {
      ...filterRadio.iconStyle,
      marginRight: '0'
    },
    style: {
      ...filterRadio.style,
      width: '7.5rem'
    }
  }
};

export const tableFloatingBarStyles = {
  wrapper: {
    opacity: 0,
    position: 'sticky',
    right: 0,
    width: 0,
    zIndex: 4
  },
  bar: {
    alignItems: 'center',
    backgroundColor: colors.dotmapsGrayButtonBackground,
    display: 'flex',
    height: '46px',  // height and top are set like this to not hide the 1px row border,
                     // and address the different behavior between Firefox and Chrome.
    position: 'absolute',
    right: 0,
    top: '1px'
  }
};

export const snackbarStyles = isOpen => ({
  bodyStyle: {
    height: isOpen ? '48px' : '0px',
    transition: transitions.easeOut('500ms', 'height')
  },
  style: {
    transform: 'translate(-50%, 0)',
    transition: transitions.easeOut('500ms', 'visibility')
  }
});

const defaultInputSearchStyles = {
  autocompleteContainerStyles: {
    maxWidth: 'none',
    width: '100%'
  },
  containerStyles: {
    margin: '1rem 0 1rem 0'
  }
};

export const autocompleteSearchStyles = {
  normal: {
    ...defaultInputSearchStyles
  },
  topBar: {
    ...defaultInputSearchStyles,
    autocompleteContainerStyles: {
      ...defaultInputSearchStyles.autocompleteContainerStyles,
      backgroundColor: 'white',
      borderRadius: '4px'
    },
    containerStyles: {
      ...defaultInputSearchStyles.containerStyles,
      maxHeight: '2.5rem',
      width: '512px'
    }
  }
};

export const mentionStyles = {
  // Styles for the wrapping MentionsInput component:
  input: {
    control: {
      backgroundColor: 'white',
      color: colors.dotmapsBlack60,
      fontFamily: 'Roboto',
      fontSize: '0.875rem',
      fontWeight: '400',
      lineHeight: '1.125rem',
      minHeight: '1.125rem'
    },
    '&multiLine': {
      highlighter: {
        padding: '0.75rem',
        border: '1px solid transparent',
        borderRadius: '0.25rem'
      },
      input: {
        padding: '0.75rem',
        border: `1px solid ${colors.dotmapsGrayBorder}`,
        borderRadius: '0.25rem'
      }
    },
    suggestions: {
      list: {
        backgroundColor: 'white',
        border: `1px solid ${colors.dotmapsGrayBorder}`,
        fontFamily: 'Roboto',
        fontSize: '0.875rem',
        overflowY: 'auto',
        height: '11.5rem',  // 184px
        width: '20rem'  // 320px
      },
      item: {
        '&focused': {
          backgroundColor: colors.dotmapsGrayHover
        }
      }
    }
  },
  // Styles for the Mention component:
  mention: {
    // Try to emulate a Material UI Chip with these CSS statements:
    backgroundColor: 'rgb(224, 224, 225)',
    borderRadius: '1rem',
    fontFamily: 'Roboto',
    // We can only add padding at the top and bottom to emulate the look and feel of a Chip component,
    // if we add left/right padding, rendering will fail, since the react-mentions library does
    // some work calculating space. We have to emulate that leading/trailing spaces by adding actual
    // leading/trailing whitespace.
    padding: '0.25rem 0 0.25rem 0'
  }
};

export const blankIconStyles = {
  alignItems: 'center',
  display: 'flex',
  fontFamily: 'Roboto',
  color: colors.dotmapsBlack80,
  fontSize: '0.875rem',
  justifyContent: 'center',
  width: '20px'
};

// Properties for the Material UI Select component, basically we set
// zIndex to a value that will make menus appear in front of everything,
// mainly due to the Dialog's zIndexes.
export const SAFE_ZINDEX = { zIndex: 1600 };

export const paper = {
  color: colors.colors.opacity.black0_8,
  backgroundColor: colors.colors.neutral.white,
  transition: '450ms cubic-bezier(0.23, 1, 0.32, 1)',
  boxSizing: 'border-box',
  fontFamily: 'Roboto',
  boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 10px, rgba(0, 0, 0, 0.23) 0px 3px 10px',
  borderRadius: '2px'
};

export const listItem = {
  color: colors.colors.opacity.black0_8,
  backgroundColor: colors.colors.neutral.white,
  transition: '450ms cubic-bezier(0.23, 1, 0.32, 1)',
  boxSizing: 'border-box',
  fontFamily: 'Roboto',
  fontSize: '1rem',
  lineHeight: '1rem',
  cursor: 'pointer',
  border: '10px',
  position: 'relative',
  padding: '0',
  margin: '0'
};

export default muiTheme;

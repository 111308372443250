import React, { memo, useState } from 'react';
import { useParams } from 'react-router-dom';
import ActivityLogs from '@components/activity-logs';
import DetailsContent from '@components/details/details-content';
import { isWorkflowEnabled } from '@constants/config';
import { Tab, Tabs } from '@shared/tabs';
import EntityComments from './comments';
import Cycles from './cycles';
import Files from './files';
import Groups from './groups';
import Info from './info';

const Details = () => {
  const { id, action } = useParams();
  const [tab, setTab] = useState('info');

  return (
    <DetailsContent>
      <Tabs
        onChange={setTab}
        tabItemWidth="110px"
        tabTemplateStyle={{ height: '100%'}}
        value={tab}
      >
        <Tab label="INFO" value="info">
          {tab === 'info' && <Info />}
        </Tab>
        {id && action !== 'duplicate' && isWorkflowEnabled() && (
          <Tab label="WORKFLOW" value="cycles">
            {tab === 'cycles' && <Cycles />}
          </Tab>
        )}
        {id && action !== 'duplicate' && (
          <Tab label="GROUPS" value="groups">
            {tab === 'groups' && <Groups />}
          </Tab>
        )}
        {id && action !== 'duplicate' && (
          <Tab label="FILES" value="files">
            {tab === 'files' && <Files />}
          </Tab>
        )}
        {id && action !== 'duplicate' && (
          <Tab label="COMMENTS" value="comments">
            {tab === 'comments' && <EntityComments />}
          </Tab>
        )}
        {id && action !== 'duplicate' && (
          <Tab label="ACTIVITY LOG" value="activity-log">
            {tab === 'activity-log' && (
              <ActivityLogs
                embedded
                params={{ app: 'map', model: 'entity', dataId: id }}
              />
            )}
          </Tab>
        )}
      </Tabs>
    </DetailsContent>
  );
};

export default memo(Details);

import React, { memo, useCallback, useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  updatePolygon,
  updateSegmentEndpoints
} from '@actions/data-detail-actions';
import InfoContext from '@components/entity/info/info-context';
import MapEdit from '@components/entity/info/map-edit';
import FloatingBar from '@components/map/floating-bar';
import OldFloatingBar from '@components/map/floating-bar-old';
import LayersMenu from '@components/map/layers-menu';
import { isNewLayout } from '@constants/config';
import { convertFromGoogleMapsShape } from '@utils/map-tools-utils';
import './map.scss';

const Map = () => {
  const dispatch = useDispatch();
  const segments = useSelector(state => state.dataDetail?.data?.segments);
  const {
    activeSegmentId,
    activeStart,
    setActiveStart,
    activeEnd,
    setActiveEnd
  } = useContext(InfoContext);

  const activeSegment = useMemo(
    () => segments?.find(seg => seg.id === activeSegmentId),
    [segments, activeSegmentId]
  );

  const onActiveSegmentChanged = useCallback(
    endPoints => {
      const newEndPoints = { ...{ start: activeStart, end: activeEnd }, ...endPoints };
      setActiveStart(newEndPoints.start);
      setActiveEnd(newEndPoints.end);
      dispatch(updateSegmentEndpoints(newEndPoints, activeSegmentId, activeSegment.override_street_centering, 'map'));
    },
    [activeEnd, activeSegment, activeSegmentId, activeStart, dispatch, setActiveEnd, setActiveStart]
  );

  const onActivePolygonChanged = useCallback(
    mapsShape => {
      setActiveStart(null);
      setActiveEnd(null);
      const shape = convertFromGoogleMapsShape(mapsShape);
      dispatch(updatePolygon(activeSegmentId, shape, 'map'));
    },
    [activeSegmentId, dispatch, setActiveEnd, setActiveStart]
  );

  return (
    <div styleName="map-container">
      {isNewLayout() && <FloatingBar legend={false} tools={false} />}
      {!isNewLayout() && <OldFloatingBar legend={false} tools={false} />}
      <LayersMenu style={{ right: '1rem' }} />
      <MapEdit
        segments={segments}
        onActiveSegmentChanged={onActiveSegmentChanged}
        onPolygonChanged={onActivePolygonChanged}
      />
    </div>
  );
};

export default memo(Map);

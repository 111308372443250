import React, { Fragment, memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import { showConfirmationDialog } from '@actions/confirmation-actions';
import { openDashboardDialog } from '@actions/dashboard-actions';
import {
  fetchDataDetail,
  getConfirmableDeleteAction
} from '@actions/data-detail-actions';
import TableHeader from '@components/entity/table-header';
import * as dialog from '@constants/dialogs';
import { CYCLES_TAB_COLUMNS as columns } from '@constants/table';
import StartCycleDialog from '@shared/dialogs/start-cycle-dialog';
import Table from '@shared/table';
import { openInNewTab } from '@utils/navigation-utils';
import { canAccessWorkflow } from '@utils/permission-utils';

// eslint-disable-next-line no-unused-vars
const onRowClick = (event, row, index) => openInNewTab(`/cycle/${row.id}`);

const Cycles = () => {
  const dispatch = useDispatch();
  const { dataType } = useParams();
  const { data } = useSelector(state => state.dataDetail);

  const onAdd = useCallback(
    () => {
      const params = { entityId: data?.id, reloadEntity: true };
      dispatch(openDashboardDialog(dialog.START_CYCLE, params));
    },
    [data?.id, dispatch]
  );

  const rows = useMemo(() => data?.cycles, [data?.cycles]);

  const deleteCycle = useCallback(row => dispatch(showConfirmationDialog(
    'Delete workflow?',
    `Are you sure you want to delete workflow "${row.name}"?`,
    getConfirmableDeleteAction(
      'cycle',
      'cycle',
      row.id,
      () => dispatch(fetchDataDetail(dataType, data?.id))
    )
  )), [dataType, data?.id, dispatch]);

  const actions = useMemo(() => {
    if (canAccessWorkflow('cycle', 'delete')) {
      return [
        { icon: DeleteIcon, label: 'Delete workflow', onClick: deleteCycle }
      ];
    }
    return null;
  }, [deleteCycle]);

  if (!data) {
    return null;
  }

  return (
    <Fragment>
      <Table
        actions={actions}
        columns={columns}
        empty={{ message: 'No workflows started yet' }}
        header={<TableHeader count={rows.length} label="workflow" onAdd={onAdd} tooltip="Start a workflow" />}
        onRowClick={onRowClick}
        rows={rows}
        style={{ maxWidth: '50rem' }}
      />
      <StartCycleDialog />
    </Fragment>
  );
};

export default memo(Cycles);

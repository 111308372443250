/* eslint-disable max-params */
/* global google */
import { push, replace } from 'connected-react-router';
import * as R from 'ramda';
import axios from 'axios';
import { v4 as uuid } from 'uuid';
import { pushApplicationMessage } from './messages-actions';
import { closeDashboardDialog, pushDashboardMessage } from './dashboard-actions';
import { discardChangesAction } from './confirmation-actions';
import {
  CLEAR_DATATABLE_ROWS,
  DATA_DETAIL_OPTIONS_FETCH_SUCCESS,
  DATA_DETAIL_OPTIONS_FETCH_ERROR,
  DATA_DETAIL_FETCH_INIT,
  DATA_DETAIL_FETCH_SUCCESS,
  DATA_DETAIL_FETCH_ERROR,
  DATA_DETAIL_SAVE_INIT,
  DATA_DETAIL_SAVE_SUCCESS,
  DATA_DETAIL_SAVE_ERROR,
  DATA_DETAIL_ERROR_FIELD,
  DATA_DETAIL_UPDATE_FIELD,
  DATA_DETAIL_CREATE_SEGMENT,
  DATA_DETAIL_SWAP_FIELDS,
  DATA_DETAIL_CLEAR_SCHEDULE,
  DATA_DETAIL_CLONE_SEGMENT,
  DATA_DETAIL_DELETE_SEGMENT,
  DATA_DETAIL_SEGMENT_UPDATE_FIELD,
  DATA_DETAIL_SEGMENT_UPDATE,
  DATA_DETAIL_COMPUTE_SEGMENT_START,
  DATA_DETAIL_COMPUTE_SEGMENT_SUCCESS,
  DATA_DETAIL_UPDATE_POLYGON,
  DATA_DETAIL_COMPUTE_SEGMENT_ERROR,
  DATA_DETAIL_DELETE_SUCCESS,
  DATA_DETAIL_DELETE_ERROR,
  DATA_DETAIL_MODIFIED,
  DATA_DETAIL_SET_ACTIVE_TAB,
  DATA_DETAIL_FETCH_OVERLAP_ENTITIES_SUCCESS,
  DATA_DETAIL_FETCH_OVERLAP_ENTITIES_ERROR,
  DATA_DETAIL_SCROLL_TO_FIELD,
  ENTITY_ADD_SINGLE
} from '@constants/action-types';
import * as dialog from '@constants/dialogs';
import { BASE_API_URL, getDetailAPIRequestUrl, getPublicDetailAPIRequestUrl } from '@constants/endpoints';
import {
  decodeDetails, encodeDetails, getAction, getTemplate, mergeActionWithTemplate, computeMetadataDefaults, createTemporalId
} from '@utils/data-detail-utils';
import { buildConfirmableAction } from '@utils/confirmation-utils';
import { getConfig } from '@utils/config-utils';
import { optimizeEntitiesForMap } from '@utils/entity-utils';
import { openDialog } from './dialogs-actions';

const setModifiedAction = modified => ({ type: DATA_DETAIL_MODIFIED, modified });

export const setModified = modified => dispatch => {
  dispatch(discardChangesAction(false));
  dispatch(setModifiedAction(modified));
};

const getGeocodeUrl = () => `${BASE_API_URL}/street_segment/`;

const fetchDataDetailOptionsSuccess = (payload, type) => ({
  type: DATA_DETAIL_OPTIONS_FETCH_SUCCESS,
  payload,
  dataType: type
});

const fetchDataDetailOptionsError = (error, type) => ({
  type: DATA_DETAIL_OPTIONS_FETCH_ERROR,
  payload: error,
  dataType: type
});

const fetchDataDetailOptions = (type, id = null, isPublic = false) => {
  const url = isPublic ? getPublicDetailAPIRequestUrl(type, id) : getDetailAPIRequestUrl(type, id);
  const request = axios.options(url);
  return dispatch => request.then(
    payload => {
      dispatch(fetchDataDetailOptionsSuccess(payload, type));
      return payload;
    },
    error => {
      dispatch(fetchDataDetailOptionsError(error.response, type));
      return Promise.reject(error);
    }
  );
};

const fetchDataDetailSuccess = (data, type, isNew) => ({
  type: DATA_DETAIL_FETCH_SUCCESS,
  data,
  dataType: type,
  isNew
});

const fetchDataDetailError = (error, type) => ({
  type: DATA_DETAIL_FETCH_ERROR,
  payload: error,
  dataType: type
});

export const createNewSegment = () => dispatch => {
  dispatch({
    type: DATA_DETAIL_CREATE_SEGMENT
  });
  return Promise.resolve();
};

export const updateSegmentField = (segmentId, fieldName, value, error) => dispatch => {
  dispatch(setModified(true));
  dispatch({
    type: DATA_DETAIL_SEGMENT_UPDATE_FIELD,
    segmentId,
    fieldName,
    value,
    error
  });
};

export const updateSegment = segment => dispatch => {
  dispatch(setModified(true));
  dispatch({
    type: DATA_DETAIL_SEGMENT_UPDATE,
    segment
  });
};

export const createNewDataDetail = (type, isPublic = false) => {
  const optionsDispatch = fetchDataDetailOptions(type, null, isPublic);
  return (dispatch, getState) => dispatch(optionsDispatch).then(
    optionsPayload => {
      const action = computeMetadataDefaults(
        mergeActionWithTemplate(
          getAction(optionsPayload.data).action, getTemplate(type)
        ),
        getState().dataTypes
      );
      const newDetails = decodeDetails(
        {segments: [], created: new Date(), modified: new Date()},
        action
      );
      dispatch(fetchDataDetailSuccess(newDetails, type, true));
      return newDetails;
    },
    () => {}
  );
};

export const swapSegmentFields = (id, from, to) => dispatch => {
  dispatch(setModified(true));
  dispatch({
    type: DATA_DETAIL_SWAP_FIELDS,
    id,
    from,
    to
  });
};

export const clearSchedule = id => dispatch => {
  dispatch(setModified(true));
  dispatch({
    type: DATA_DETAIL_CLEAR_SCHEDULE,
    id
  });
};

export const cloneSegment = id => dispatch => {
  dispatch(setModified(true));
  dispatch({
    type: DATA_DETAIL_CLONE_SEGMENT,
    id
  });
};

export const deleteSegment = id => dispatch => {
  dispatch(setModified(true));
  dispatch({
    type: DATA_DETAIL_DELETE_SEGMENT,
    id
  });
};

const initFetch = () => dispatch => {
  dispatch({ type: DATA_DETAIL_FETCH_INIT });
  return Promise.resolve();
};

export const fetchDataDetail = (type, id) => (dispatch, getState) => dispatch(initFetch()).then(() => {
  const optionsDispatch = fetchDataDetailOptions(type, id);
  const url = getDetailAPIRequestUrl(type, id);
  return dispatch(optionsDispatch).then(
    optionsPayload => axios.get(url).then(
      payload => {
        let { action } = getAction(optionsPayload.data);
        const template = getTemplate(type);
        if (template) {
          action = computeMetadataDefaults(
            mergeActionWithTemplate(action, template),
            getState().dataTypes
          );
        }
        const data = decodeDetails(payload.data, action);
        dispatch(fetchDataDetailSuccess(data, type, false));
        return Promise.resolve(data);
      },
      ({response: errorPayload}) => {
        if (errorPayload.status === 404) {
          dispatch(openDialog(type));
        }
        dispatch(fetchDataDetailError(errorPayload, type));
        return Promise.reject(errorPayload);
      }
    ),
    () => {}
  );
});

export const createDuplicateDataDetail = (type, id) => (dispatch, getState) => dispatch(initFetch()).then(() => {
  const optionsDispatch = fetchDataDetailOptions(type, id);
  const url = getDetailAPIRequestUrl(type, id);
  return dispatch(optionsDispatch).then(
    optionsPayload => axios.get(url).then(
      payload => {
        let { action } = getAction(optionsPayload.data);
        const template = getTemplate(type);
        if (template) {
          action = computeMetadataDefaults(
            mergeActionWithTemplate(action, template),
            getState().dataTypes
          );
        }
        const data = decodeDetails(payload.data, action);
        data.id = '';
        delete data.overlaps;
        delete data.group_ids;
        delete data.cycles;
        // eslint-disable-next-line max-nested-callbacks
        data.segments = data.segments.map(segment => {
          segment.id = createTemporalId();
          return segment;
        });
        dispatch(fetchDataDetailSuccess(data, type, false));
        return Promise.resolve(data);
      },
      ({response: errorPayload}) => {
        if (errorPayload.status === 404) {
          dispatch(openDialog(type));
        }
        dispatch(fetchDataDetailError(errorPayload, type));
        return Promise.reject(errorPayload);
      }
    ),
    () => {}
  );
});

const fetchOverlapEntitiesSuccess = payload => ({ type: DATA_DETAIL_FETCH_OVERLAP_ENTITIES_SUCCESS, payload });

const fetchOverlapEntitiesError = (payload, error) => ({ type: DATA_DETAIL_FETCH_OVERLAP_ENTITIES_ERROR, payload, error });

let fetchOverlapSource = axios.CancelToken.source();

export const fetchOverlapEntities = id => {
  const url = getDetailAPIRequestUrl('overlap', id);
  fetchOverlapSource.cancel();
  fetchOverlapSource = axios.CancelToken.source();
  const request = axios.get(url, {cancelToken: fetchOverlapSource.token});
  return dispatch => request.then(
    payload => {
      const overlapEntities = {};
      overlapEntities[id] = {
        conflicts: payload.data.conflicts,
        opportunities: payload.data.opportunities
      };
      dispatch(fetchOverlapEntitiesSuccess(overlapEntities));
    },
    error => {
      if (axios.isCancel(error)) {
        return;
      }
      const overlapEntities = {};
      overlapEntities[id] = {
        conflicts: [],
        opportunities: []
      };
      dispatch(fetchOverlapEntitiesError(overlapEntities, error));
    }
  );
};

const saveDataDetailSuccess = (payload, type) => ({
  type: DATA_DETAIL_SAVE_SUCCESS,
  payload,
  dataType: type
});

const validateDates = (typeDisplay, formData) => {
  const errors = {};
  if ('start_date' in formData &&
      'end_date' in formData) {
    const startDate = formData.start_date;
    const endDate = formData.end_date;
    if (startDate && endDate) {
      if (startDate.isAfter(endDate)) {
        errors.start_date = [' '];
        errors.end_date = [`${typeDisplay} must end after it starts.`];
      }
    }
  }
  return errors;
};

const validateProjectManager = (typeDisplay, formData) => {
  const errors = {};
  if ('project_manager' in formData && getConfig().pmRequired) {
    if (formData.project_manager === null) {
      errors.project_manager = ['Select a project manager.'];
    }
  }
  return errors;
};

const getErrorData = error => {
  if (error.status === 500) {
    // On a 500 server error, the backend doesn't
    // return errors per field, but a generic
    // "500 internal server error", thus there's
    // no error fields to process:
    return {};
  }
  return error.data;
};

const saveDataDetailError = (error, type, typeDisplay, formData) => ({
  type: DATA_DETAIL_SAVE_ERROR,
  payload: {
    ...error,
    data: {
      ...getErrorData(error),
      ...validateDates(typeDisplay, formData),
      ...validateProjectManager(typeDisplay, formData)
    }
  },
  dataType: type
});

const initSave = () => {
  return dispatch => {
    dispatch({
      type: DATA_DETAIL_SAVE_INIT
    });
    return Promise.resolve();
  };
};

// Export it for unit tests:
export const saveDataDetail = (type, typeDisplay, data, saveMetadata, options, isPublic = false) => {
  return (dispatch, getState) => dispatch(initSave()).then(() => {
    // const url = getDetailAPIRequestUrl(type, data.id);
    const url = isPublic ? getPublicDetailAPIRequestUrl(type, data.id) : getDetailAPIRequestUrl(type, data.id);
    let { action } = getAction(options);
    // Add the 'front_end' flag to tell we are sending all fields from the UI.
    // Since the backend can also be called with scripts to perform partial updates.
    let encodedData = {
      ...encodeDetails(data, saveMetadata),
      front_end: true
    };
    // Build the entity categories:
    const categories = [];
    Object.keys(data).forEach(key => {
      if (key.charAt(0) === '|') {
        // Pull category data from 'data', not 'encodedData',
        // since when data is encoded, category values
        // are unpacked again from the original values.
        const category = data[key];
        categories.push(category);
      }
    });
    encodedData = { ...encodedData, categories };
    const request = data.id ? axios.put(url, encodedData) : axios.post(url, encodedData);
    return request.then(
      payload => {
        dispatch(setModified(false));
        const template = getTemplate(type);
        if (template) {
          action = computeMetadataDefaults(
            mergeActionWithTemplate(action, template),
            getState().dataTypes
          );
        }
        const newPayload = {...payload, data: decodeDetails(payload.data, action)};
        dispatch(saveDataDetailSuccess(newPayload, type));
        const { agency_type, map_type } = getState().dataTypes;
        const entity = optimizeEntitiesForMap([payload.data], type, { agency_type, map_type })[0];
        dispatch({ type: ENTITY_ADD_SINGLE, entity, entityType: type });
        return Promise.resolve(newPayload);
      },
      ({response: errorPayload}) => {
        dispatch(saveDataDetailError(errorPayload, type, typeDisplay, data));
        return Promise.reject(errorPayload);
      }
    );
  });
};

export const clearDataType = (type, subType) => dispatch => {
  dispatch({
    type: CLEAR_DATATABLE_ROWS,
    dataType: type,
    subType
  });
  return Promise.resolve();
};

// Clears the data type data from the Redux store and redirect to the specified page,
// that page will reload the data, since it's no longer on the store, this is required
// for "refreshing" a page after a save or update operation.
const refreshAndRedirect = (dataType, source) => dispatch => dispatch(
  clearDataType(dataType)
).then(() => {
  dispatch(push(source));
});

export const navigateAfterSave = (dataType, dataId, source) => dispatch => {
  let newLocation;
  if (!source || source === '/map' || source === '/map/') {
    newLocation = {
      pathname: '/map',
      search: `?${dataType}=${dataId}`
    };
  } else {
    newLocation = source;
  }
  dispatch(refreshAndRedirect(dataType, newLocation));
  return Promise.resolve();
};

export const saveAndNavigate = (
  dataType, typeDisplay, data, saveMetadata, options, source, isPublic = false
) => dispatch => dispatch(
  saveDataDetail(dataType, typeDisplay, data, saveMetadata, options, isPublic)
).then(payload => {
  if (payload.data && payload.data.id) {
    dispatch(navigateAfterSave(dataType, payload.data.id, source)).then(() => {
      dispatch(pushApplicationMessage(`${typeDisplay} has been saved. ID ${payload.data.id}`));
    });
  }
  return payload;
});

const reload = (type, id) => dispatch => {
  dispatch(replace(`/${type}/${id}`));
  return Promise.resolve();
};

export const saveAndReload = (
  dataType, typeDisplay, data, saveMetadata, options, isPublic = false
) => dispatch => dispatch(
  saveDataDetail(dataType, typeDisplay, data, saveMetadata, options, isPublic)
).then(payload => {
  if (payload.data && payload.data.id) {
    dispatch(reload(dataType, payload.data.id)).then(() => {
      dispatch(pushApplicationMessage('Changes saved'));
    });
  }
  return payload;
});

export const saveAndStay = (
  dataType, typeDisplay, data, saveMetadata, options, isPublic = false
) => dispatch => dispatch(
  saveDataDetail(dataType, typeDisplay, data, saveMetadata, options, isPublic)
).then(payload => {
  if (payload.data && payload.data.id) {
    dispatch(pushApplicationMessage('Changes saved'));
  }
  return payload;
});

export const getConfirmableSaveAndNavigateAction = buildConfirmableAction(
  saveAndNavigate, 'dataDetailsaveAndNavigate'
);

const deleteDataDetailSuccess = (type, dataId) => ({
  type: DATA_DETAIL_DELETE_SUCCESS,
  dataType: type,
  dataId
});

const deleteDataDetailError = (error, type, dataId) => ({
  type: DATA_DETAIL_DELETE_ERROR,
  payload: error,
  dataType: type,
  dataId
});

export const deleteDataDetail = (type, typeDisplay, dataId, clear = true) => {
  const url = getDetailAPIRequestUrl(type, dataId);
  const request = axios.delete(url);
  return dispatch => request.then(
    () => {
      // Clear store after delete.
      if (clear) {
        dispatch(deleteDataDetailSuccess(type, dataId));
      }
      dispatch(pushApplicationMessage(`${typeDisplay} has been deleted. ID ${dataId}`));
    },
    ({ response: error }) => dispatch(deleteDataDetailError(error, type, dataId))
  );
};

export const copyToClipboard = (textId, displayId) => dispatch => {
  // Select the text (as a visual effect for the user):
  const displayNode = document.querySelector(`#${displayId}`);
  if (document.body.createTextRange) {  // IE
    const range = document.body.createTextRange();
    range.moveToElementText(displayNode);
    range.select();
  } else
    if (window.getSelection) {
      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(displayNode);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  // And copy the real content from the text area
  // into the clipboard:
  const textArea = document.querySelector(`#${textId}`);
  textArea.focus();
  textArea.select();
  const closeDialog = () => {
    setTimeout(() => dispatch(closeDashboardDialog(dialog.VIEW_SCHEDULE)), 3000);
  };
  try {
    const success = document.execCommand('copy');
    if (success) {
      dispatch(pushDashboardMessage('Successfully copied to clipboard.'));
      closeDialog();
      return;
    }
  } catch (err) {
    // eslint-disable-line no-empty
  }
  dispatch(pushDashboardMessage('Cannot copy to clipboard.'));
  closeDialog();
};

export const navigateAfterDelete = source => dispatch => {
  dispatch(replace(source || '/map'));
};

export const deleteAndNavigate = (type, typeDisplay, dataId, source, callback) => dispatch => {
  return dispatch(deleteDataDetail(type, typeDisplay, dataId)).then(payload => {
    dispatch(navigateAfterDelete(source));
    if (callback) {
      callback();
    }
    return payload;
  });
};

export const deleteAndStay = (type, typeDisplay, dataId, callback) => dispatch => {
  return dispatch(deleteDataDetail(type, typeDisplay, dataId, false)).then(payload => {
    if (callback) {
      callback();
    }
    return payload;
  });
};

export const getConfirmableDeleteAndNavigateAction = buildConfirmableAction(
  deleteAndNavigate, 'dataDetailDeleteAndNavigate'
);

export const getConfirmableDeleteAction = buildConfirmableAction(
  deleteAndStay, 'dataDetailDelete'
);

const newDataValue = (field, value) => ({
  type: DATA_DETAIL_UPDATE_FIELD,
  fieldName: field,
  value
});

export const scrollToField = scrollId => dispatch => dispatch({
  type: DATA_DETAIL_SCROLL_TO_FIELD,
  scrollId
});

export const updateDataField = (field, value) => dispatch => {
  dispatch(setModified(true));
  dispatch(newDataValue(field, value));
};

// Like updateDataField(), but for inline editing (which saves the value as soon
// as it's changed, thus there's no need to set the 'modified' flag, which is
// the one used to warn the user when he tries to navigate off the page).
export const updateDataFieldInline = (field, value) => dispatch => {
  dispatch(newDataValue(field, value));
};

const errorDataValue = (fieldName, error, clear) => ({
  type: DATA_DETAIL_ERROR_FIELD,
  fieldName,
  error,
  clear
});

export const errorDataField = (field, error, clear) => dispatch => {
  dispatch(errorDataValue(field, error, clear));
};

const runGeocodeWithPromise = (query, geocoder, attempts = 0) => {
  return new Promise(resolve => {
    geocoder.geocode(query, (result, status) => {
      if (status === google.maps.GeocoderStatus.OVER_QUERY_LIMIT && attempts < 5) {
        setTimeout(() => {
          resolve(runGeocodeWithPromise(query, geocoder, attempts + 1));
        }, attempts * 1000);
      } else {
        resolve(R.pathOr(null, [0, 'formatted_address'], result));
      }
    });
  });
};

const memoizedRunGeocodeWithPromise = R.memoizeWith(
  (query) => (JSON.stringify(query)),
  (query, geocoder, attempts) => runGeocodeWithPromise(query, geocoder, attempts)
);

const buildSimpleSegment = (start, end) => {
  let geocoder = null;
  let startPromise = null;
  let endPromise = null;
  let shape = null;
  if (google) {
    geocoder = new google.maps.Geocoder();
  }
  if (start) {
    if (end) {
      shape = {type: 'LineString', coordinates: [[start.lng, start.lat], [end.lng, end.lat]]};
      endPromise = geocoder ? memoizedRunGeocodeWithPromise({location: end}, geocoder) : null;
    } else {
      shape = {type: 'Point', coordinates: [start.lng, start.lat]};
    }
    startPromise = geocoder ? memoizedRunGeocodeWithPromise({location: start}, geocoder) : null;
  }
  return new Promise(resolve => {
    Promise.all([startPromise, endPromise]).then(([displayFrom, displayTo]) => resolve({
      from_address: null,
      from_street: null,
      on_street: '',
      shape,
      to_address: null,
      to_street: null,
      display_from: displayFrom,
      display_to: displayTo
    }));
  });
};

const runSegmentGeocode = (start, end) => {
  if (start) {
    let geocodeUrl = `${getGeocodeUrl()}?lnglat=${start.lng},${start.lat}`;
    if (end) {
      geocodeUrl += `&lnglat=${end.lng},${end.lat}`;
    }
    const request = axios.get(geocodeUrl);
    return request.then(
      payload => {
        const results = payload.data;
        if (results.length > 0) {
          const {feature, ...rest} = results[0];
          return {
            ...rest,
            shape: feature.geometry
          };
        }
        return buildSimpleSegment(start, end);
      },
      () => buildSimpleSegment(start, end)
    );
  }
  return Promise.resolve({});
};

export const updateSegmentEndpoints = ({start, end}, segmentId, overrideStreetCentering, changeSource = 'unknown') => {
  const updateId = uuid();
  return dispatch => {
    dispatch(setModified(true));
    dispatch({
      type: DATA_DETAIL_COMPUTE_SEGMENT_START,
      segmentId,
      updateId
    });
    if (overrideStreetCentering) {
      return buildSimpleSegment(start, end).then(segment => dispatch({
        type: DATA_DETAIL_COMPUTE_SEGMENT_SUCCESS,
        segment: {...segment, id: segmentId, lastModifiedBy: changeSource},
        updateId
      }));
    }
    return runSegmentGeocode(start, end).then(
      segment => {
        dispatch({
          type: DATA_DETAIL_COMPUTE_SEGMENT_SUCCESS,
          segment: {
            ...{from_address: null, from_street: '', to_address: null, to_street: ''},
            ...segment, id: segmentId, lastModifiedBy: changeSource
          },
          updateId
        });
      },
      ({response: errorPayload}) => dispatch({
        type: DATA_DETAIL_COMPUTE_SEGMENT_ERROR,
        errorPayload,
        updateId
      })
    );
  };
};

export const updatePolygon = (segmentId, shape, changeSource = 'unknown') => {
  return dispatch => {
    dispatch(setModified(true));
    dispatch({
      type: DATA_DETAIL_UPDATE_POLYGON,
      segmentId,
      shape,
      changeSource
    });
  };
};

export const setActiveTab = (dataType, tab) => dispatch => {
  dispatch({ type: DATA_DETAIL_SET_ACTIVE_TAB, dataType, tab });
};

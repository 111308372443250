import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useParams } from 'react-router-dom';
import { isWorkflowEnabled } from '@constants/config';
import AddToGroupDialog from '@shared/dialogs/add-to-group-dialog';
import StartCycleDialog from '@shared/dialogs/start-cycle-dialog';
import { getDataType, isListingPage } from '@utils/dashboard-utils';
import DateRangeDialog from './dialogs/date-range-dialog';
import Footer from './footer';
import Menu from './menu';
import TopBar from './top-bar';
import TopFilterBar from './top-filter-bar';
import './dashboard.scss';

const Dashboard = ({ children }) => {
  const location = useLocation();
  const params = useParams();
  const dataType = getDataType(params, location);
  const addFooter = isListingPage(location);
  return (
    <div data-testid="dashboard" styleName="dashboard-content-container">
      <TopBar params={params} location={location} />
      <TopFilterBar dataType={dataType} params={params} location={location} />
      <div styleName={`dashboard-container ${addFooter ? 'with-footer' : 'no-footer'}`}>
        <Menu params={params} location={location} />
        <div styleName="dashboard-content">
          {children}
        </div>
      </div>
      {addFooter && <Footer params={params} location={location} />}
      <DateRangeDialog dataType={dataType} params={params} location={location} />
      <AddToGroupDialog />
      {isWorkflowEnabled() && <StartCycleDialog />}
    </div>
  );
};

Dashboard.propTypes = {
  children: PropTypes.element
};

export default memo(Dashboard);

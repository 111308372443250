/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-undefined */
import React, { useCallback, useMemo, memo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { isString } from 'lodash';
import moment from 'moment-timezone';
import { SAFE_ZINDEX } from '@constants/mui-theme';
import Schedule from '@material-ui/icons/Schedule';
import { KeyboardTimePicker } from '@material-ui/pickers';

const unawareFormat = 'YYYY-MM-DDTHH:mm:ss';

const scheduleIcon = <Schedule style={{ fontSize: '18px' }} />;

const defaultProps = {
  DialogProps: { style: SAFE_ZINDEX },
  KeyboardButtonProps: { 'aria-label': 'change date' },
  autoOk: true,
  margin: 'normal',
  mask: '__:__ _M'
};

const MomentTimePicker = ({
  disabled,
  errorText,
  floatingLabelText,
  fullWidth,
  id,
  onChange,
  style,
  value
}) => {
  const { timezoneName } = useSelector(state => state.config);

  const onAccept = useCallback(time => {
    if (time) {
      let newTime = time;
      if (isString(time)) {
        // If date is a string, it means it was manually entered,
        // (i.e. not through the time picker dialog),
        // thus parse it.
        newTime = moment(time, 'hh:mm A');
      }
      const unawareString = moment(newTime).format(unawareFormat);
      const fixedTime = moment.tz(unawareString, timezoneName);
      onChange(fixedTime);
    }
  }, [onChange, timezoneName]);

  const pickerProps = useMemo(() => {
    if (errorText) {
      return {
        error: true,
        helperText: errorText
      };
    }
    return {};
  }, [errorText]);

  return (
    <div style={style}>
      <KeyboardTimePicker
        {...pickerProps}
        {...defaultProps}
        disabled={disabled}
        id={id}
        inputProps={{style: disabled ? {color: 'rgba(0,0,0,0.3)'} : {}}}
        fullWidth={fullWidth}
        keyboardIcon={scheduleIcon}
        label={floatingLabelText}
        onAccept={onAccept}
        onBlur={event => onAccept(event.target.value)}
        onChange={() => undefined}
        value={typeof value === 'undefined' ? null : value}
      />
    </div>
  );
};

MomentTimePicker.propTypes = {
  disabled: PropTypes.bool,
  errorText: PropTypes.string,
  floatingLabelText: PropTypes.string,
  fullWidth: PropTypes.bool,
  id: PropTypes.string,
  onChange: PropTypes.func,
  style: PropTypes.object,
  value: PropTypes.object
};

export default memo(MomentTimePicker);

/* eslint-disable max-depth */
/* eslint-disable max-len */
import React from 'react';
import * as R from 'ramda';
import { isEmpty } from 'lodash';
import moment from 'moment-timezone';
import CoreEditField from '@components/workflow/section/core/edit-field';
import CoreViewField from '@components/workflow/section/core/view-field';
import EditField from '@components/workflow/section/edit-field';
import ViewField from '@components/workflow/section/view-field';
import {
  getWorkflowCustomFieldSections
} from '@constants/config';
import { detailEdit } from '@constants/mui-theme';
import {
  ADMIN_DETAILS_FIELDS,
  DETAILS_FIELDS,
  RESPONSE_FIELDS,
  TASK_TYPE_FIELD_TYPE_ICON
} from '@constants/workflow';
import AttributeLabel from '@shared/ui-library/attribute/attribute-label';
import { getConfigPathOr } from '@utils/config-utils';
import { getBaseWorkflowURL } from '@utils/navigation-utils';
import {
  canRespondTaskActivity,
  getAgencyId,
  getUserId,
  verifyPermissions
} from '@utils/permission-utils';

export const isAgency = activity => activity?.agency === getAgencyId();
export const isAssignee = activity => activity?.assignee === getUserId();
export const isOwnerAgency = task => task?.owner_agency_id === getAgencyId();
export const isOwner = task => task?.owner === getUserId();

export const canViewActivity = (task, activity) => (
  isOwner(task) ||  // Is the owner.
  isOwnerAgency(task) ||  // Belongs to owner's agency.
  isAgency(activity) ||  // Belongs to activity's agency.
  isAssignee(activity)  // is the assignee.
);

export const findActivity = (activityId, activities) => activities?.find(activity => activity.id === parseInt(activityId, 10));

// Return true if the current user is allowed to see the specified activity.
// Get the custom fields list for the specified task template:
const getTaskCustomFields = (taskTypes, taskTypeId) => {
  const { custom_fields } = taskTypes[taskTypeId];
  return custom_fields;
};

// From the specified fields list and section, check if the task template's
// custom fields are for this section and add them to the list of fields:
export const addCustomFields = (fields, section, taskType) => {
  const { custom_fields } = taskType;
  if (custom_fields) {
    return [
      ...fields,
      ...custom_fields.filter(field => field.section === section.id)
    ];
  }
  return fields;
};

// Updates the order field in the specified list if the overrideItem's order
// changed.
const updateOverrideItem = (list, overrideItem) => {
  const index = list.findIndex(item => item.id === overrideItem.id);
  if (index !== -1) {
    const oldOrder = list[index].order;
    const newOrder = overrideItem.order;
    // Only change the order if it changed in the overriden item:
    if (oldOrder !== newOrder) {
      let newList = [...list];
      // If the order of a field changed, we must change the order
      // of all items in the list.
      if (oldOrder > newOrder) {
        newList = [...newList.map(item => {
          if (item.order < oldOrder && item.order >= newOrder) {
            return { ...item, order: item.order + 1 };
          }
          return { ...item };
        })];
      } else {
        newList = [...newList.map(item => {
          if (item.order > oldOrder && item.order <= newOrder) {
            return { ...item, order: item.order - 1 };
          }
          return { ...item };
        })];
      }
      // Override the order on the moved element:
      newList[index] = {
        ...newList[index],
        order: overrideItem.order
      };
      return newList;
    }
  }
  return list;
};

// Return the task fields for the specified section:
export const getTaskFields = (section, taskType, overrideField = null, admin = false) => {
  let fields = [];

  // The details section has the hardcoded core fields:
  if (section.name === 'Details') {
    if (admin) {
      // For the admin page, prepend the 'task name' field:
      fields = ADMIN_DETAILS_FIELDS;
    } else {
      fields = DETAILS_FIELDS;
    }
  } else
    // Under the admin page, display all response fields:
    if (section.name === 'Response') {
      fields = RESPONSE_FIELDS;
    }

  // Add any custom fields:
  fields = addCustomFields(fields, section, taskType);

  // Override the field order (used when waiting for an API call to refresh the screen).
  if (overrideField) {
    fields = updateOverrideItem(fields, overrideField);
  }

  // Return fields sorted by the 'order' property:
  return fields.sort((a, b) => (a.order > b.order) ? 1 : -1);  // eslint-disable-line id-length
};

export const renderFields = (sectionId, fields) => fields.map(field => {
  if (field.core) {
    return <CoreViewField key={sectionId} sectionId={sectionId} {...field} />;
  }

  return <ViewField key={sectionId} field={field} />;
});

export const renderEditFields = (sectionId, fields, isPreview = false) => fields.map((field, index) => {
  if (field.core) {
    return <CoreEditField isPreview={isPreview} key={sectionId} sectionId={sectionId} {...field} />;
  }

  return <EditField isPreview={isPreview} key={sectionId} field={field} position={index} sectionId={sectionId} />;
});

// Get the max 'order' from the list of fields.
export const getFieldsMaxOrder = fields => Math.max(0, ...fields.map(({ order }) => order).filter(Boolean));

// Returns the name to use for each section on the task template edit page
// to name fields:
export const getTaskSectionTitleFieldName = section => {
  if (section.name === 'Details') {
    return 'Context fields';
  }
  return `${section.name} fields`;
};

// Validate activity response form fields:
export const validateResponseForm = activity => {
  const errors = [];
  ['assignee', 'response'].forEach(field => {
    if (!activity[field]) {
      errors.push({
        field,
        value: 'This field is required'
      });
    }
  });

  return errors;
};

// Validates that all required fields are filled:
export const validateForm = (data, taskTypes, taskTypeId) => {
  const custom_fields = getTaskCustomFields(taskTypes, taskTypeId);
  const sections = getWorkflowCustomFieldSections();
  const errors = [];

  // Validate required core fields:
  ['name', 'owner', 'activities', 'roles'].forEach(field => {
    if (!data[field]) {
      errors.push({
        field,
        value: 'This field is required'
      });
    }
  });

  // Validate custom fields from all sections but the response one:
  sections.forEach(section => {
    if (section.name === 'Response') {
      // Don't validate the response section, since that's empty for the task, it has
      // to be valid for each activity.
      return;
    }

    const customFields = custom_fields.filter(custom_field => custom_field.section === section.id);
    // For each field defined for this task template:
    customFields.forEach((customField, index) => {
      if (customField.required) {
        if (data[section.id]) {
          const { field_values } = data[section.id];
          // If the field is required, find it in the form's values:
          const field = field_values.find(field_value => field_value && field_value.field === customField.id);
          if (field) {
            const { value } = field;
            if (value) {
              // If the value is not empty, then it's valid (since we only validate for required fields):
              return;
            }
          }
        }
        // Else, flag the error, since this field is required:
        errors.push({
          field: `${section.id}.field_values.${index}.field`,
          value: 'This field is required'
        });
      }
    });
  });

  return errors;
};

// Format custom fields values, based on their data type:
const formatValue = value => {
  // Format the date if it's a date object:
  if (moment.isMoment(value)) {
    return moment(value).format('YYYY-MM-DD hh:mm A');
  }
  // Fallback, convert to str:
  return String(value);
};

// Convert the 'value' field of custom fields to str.
const valuesToString = fieldValues => fieldValues.map(({ field, value, ...other }) => ({
  field,
  value: formatValue(value),
  ...other  // 'other' may contain 'id' and 'task' (when it's an update, or nothing on task creation).
}));

// The custom field values are grouped by section in the Redux store, but we need
// to consolidate them into a single 'field_values' field to submit it to the API.
export const buildFieldValues = data => {
  let values = [];
  Object.keys(data).forEach(key => {
    // If the property is a number process the section (non-number are NaN and sections
    // starts with the number 1).
    if (parseInt(key, 10) > 0) {
      const { field_values } = data[key];
      if (field_values) {
        values = [...values, ...valuesToString(field_values.filter(Boolean))];
      }
    }
  });
  return values;
};

// Convert the list of segments into a list of segment ids:
export const buildSegmentIds = data => {
  if (data) {
    let segments = [];
    // It's a task related to an entity, all segments are on the base data object:
    if (data.segments && data.segments.length > 0) {
      segments = data.segments;
    }
    // Else it's a task related to a group, include all segments from all entities:
    const { entities } = data;
    if (entities && entities.length > 0) {
      segments = [].concat(...entities.map(entity => entity.segments));
    }
    return segments.map(segment => ({ id: segment.id }));
  }
  return [];
};

// Filter a list of entities by the specified entity ids:
export const filterSelectedEntities = (entities, entityIds) => entities.filter(entity => entityIds.includes(entity.id));

// Filter statuses to know which ones are allowed based on the current selected
// status and permissions.
const filterStatus = (currentStatus, statusItem, task, activity) => {
  // Always include the current status.
  if (statusItem.name === currentStatus) {
    return true;
  }

  // Owners can only transition to 'Completed' or 'Assigned' from the 'Submitted' status.
  if (isOwner(task) || isOwnerAgency(task)) {
    if (
      currentStatus === 'Submitted' && (
        statusItem.name === 'Completed' || statusItem.name === 'Assigned'
      )
    ) {
      return true;
    }
    // Owners can't transition to other states.
    return false;
  }

  // Responders can only transition to 'In progress' or 'Submitted'.
  if (isAssignee(activity) || isAgency(activity)) {
    // We can only transition into 'In progress' or 'Submitted' if we are in the 'Assigned' or 'In progress' state.
    if (
      (currentStatus === 'Assigned' || currentStatus === 'In progress') &&
      (statusItem.name === 'In progress' || statusItem.name === 'Submitted')
    ) {
      return true;
    }
  }

  // Disallow any other transition.
  return false;
};

// Filter the list of specified statuses based on the current status and user permissions.
export const getTaskStatuses = (activityStatus, taskStatuses, task, activity) => {
  const statuses = Object.values(taskStatuses);
  return statuses.filter(statusItem => filterStatus(activityStatus, statusItem, task, activity));
};

export const isPastDue = dueDate => {
  if (dueDate) {
    const today = moment().toDate();
    return moment(dueDate).isBefore(today);
  }
  return false;
};

// Check for activity late status.
export const isLate = (activity, taskDueDate) => {
  // Flag already set on activity:
  if (activity.is_late) {
    return true;
  }

  // If the status is Complete it means it was completed on time
  // because is_late is not set on the activity.
  if (activity.status_name === 'Completed') {
    return false;
  }

  // Else, if the task's due date is past due, then it's late.
  return isPastDue(taskDueDate) || isPastDue(activity.task_due_date);
};

export const findTaskStatus = (statusName, taskStatuses) =>
  Object.values(taskStatuses).find(taskStatus => taskStatus.name === statusName);

// Returns the default activity for the specified task.
export const getDefaultActivity = (task, taskActivityId) => {
  if (task?.activities?.length > 0) {
    const { activities } = task;
    let activity = null;

    // If the activity id is present in the URL, use that one:
    if (taskActivityId) {
      activity = activities.find(act => act.id === parseInt(taskActivityId, 10));
      if (typeof activity !== 'undefined') {
        return activity;
      }
    }

    // Return the first activity if we are the owner, or there's just one activity.
    if (isOwner(task) || activities.length === 1) {
      return activities[0];
    }

    activity = activities.find(act => {
      // If we are the assignee, always retrieve that activity:
      if (isAssignee(act)) {
        return true;
      }
      // Else check if the activity matches our agency (there's always zero
      // or one per task).
      if (isAgency(act)) {
        return true;
      }
      return false;
    });

    if (typeof activity !== 'undefined') {
      return activity;
    }

    // Else return the first activity if the task is owned by my agency
    // (but only after all checks above, since it could be owned by my
    // agency, but I could be an assignee to another activity).
    if (isOwnerAgency(task)) {
      return activities[0];
    }
  }
  return null;
};

// Build an URL based on the specified task and navigate to it.
export const goToDefaultTask = (task, replace) => {
  // Initially build the URL with the cycle and task ids.
  const pathParts = [
    'cycle', task.cycle, 'task', task.id
  ];

  // And add the activity part if it exists.
  if (task.activities.length > 0) {
    const activity = getDefaultActivity(task, null);
    if (activity) {
      pathParts.push('activity');
      pathParts.push(activity.id);
    }
  }

  // Don't set "state: { source }" in the push() call for task links,
  // else, we'll be populating the source and the top-bar's back arrow
  // link will navigate us through all tasks instead of going directly
  // to the workflow listing page:
  //
  // const source = this.props.location.pathname;
  replace({
    pathname: `/${pathParts.join('/')}`,
    state: { clear: true }
  });
};

// Returns the task assigned 'to' field for rendering activity log messages.
export const buildAssignedTaskTo = attrs => {
  if (attrs.assignee_name) {
    return `${attrs.assignee_name} (${attrs.assignee_agency})`;
  }
  if (attrs.roles) {
    return <span>{attrs.agency} &ndash; {attrs.roles.join(', ')}</span>;
  }
  return attrs.agency;
};

export const getFirstWorkflowMenuOption = () => {
  // Can't reuse getDashboardMenuConfig() here, since for the second element in the array
  // we need it to always be 'workflow':
  const menuConfig = getConfigPathOr(['dashboard', 'workflow', 'menu']);
  if (menuConfig && menuConfig.length > 0) {
    const { items } = menuConfig[0];
    const allowedItems = items.filter(item => verifyPermissions(item.permissions));
    if (allowedItems.length > 0) {
      const { type, subtype } = allowedItems[0];
      return `${getBaseWorkflowURL()}/${type}/${subtype}`;
    }
  }
  // Default to the owner view:
  return `${getBaseWorkflowURL()}/task/owner`;
};

// Tels if a user can be mentioned on an activity.
const canBeMentioned = (user, task, activity) => {
  // Check if the user's agency is the same one as the activity one:
  if (user.agency === activity.agency) {
    return true;
  }

  // Check if the user's agency is the same as the task's owner one:
  if (task.owner_agency_id === user.agency) {
    return true;
  }

  return false;
};

// Filters the specified user list retrieving only the ones that can be tagged (mentioned)
// on activity comments.
export const filterCommentUsers = (users, task, activity) => R.pickBy(user => canBeMentioned(user, task, activity), users);

// Returns the specified field's icon, or use a default one based on the field's type:
export const getTaskFieldIcon = field => {
  if (field.icon) {
    return field.icon;
  }
  if (field.type) {
    return TASK_TYPE_FIELD_TYPE_ICON[field.type];
  }
  return null;
};

// Checks if the user belongs to the specified teams:
const isInTeam = (user, teams) => user.roles.filter(role => teams.includes(role)).length > 0;

export const getTeamUsers = (data, users) => {
  if (!users || Object.values(users).length === 0) {
    return null;
  }

  const { roles } = data;

  // Display users from the selected team only:
  const teamUsers = {
    0: {
      id: null,
      is_active: true,
      is_unassigned: true,  // Tells to sort this option first in the list.
      select_unassigned: true,  // Tells to allow select the 'unassigned' option
                                // (since by default it's always a disabled entry).
      name: 'Unassigned'
    },
    ...R.pickBy(user => isInTeam(user, roles) && user.is_active, users)
  };

  // If for some reason the assignee is not in the list of users
  // (because it belongs to other agency or is a superuser),
  // add it to the list.
  if (data.assignee && !teamUsers[data.assignee]) {
    teamUsers[data.assignee] = {
      id: data.assignee,
      is_active: true,
      name: data.assignee_name,
      email: data.assignee_email
    };
  }

  return teamUsers;
};

// Get all non-draft (i.e. published) task templates that exist in the specified workflow:
export const getTaskTypesInWorkflow = (taskTypes, workflow) => Object.values(taskTypes).filter(item => item.draft === false && workflow.task_types.includes(item.correlation_id));

// Same as above but returns the task templates that does not exists in the workflow:
export const getTaskTypesNotInWorkflow = (taskTypes, workflow) => Object.values(taskTypes).filter(item => item.draft === false && !workflow.task_types.includes(item.correlation_id));

// For each task template correlation_id we can have several versions of the task template
// (i.e. old versions and the published one), thus get the one with highest id
// (we can't just query by current=true, since the current one might be in a draft state).
export const getLatestPublishedTaskTypes = taskTypes => {
  const map = new Map();
  for (const item of taskTypes) {
    const { correlation_id, id } = item;
    if (!map.has(correlation_id) || id > map.get(correlation_id).id) {
      map.set(correlation_id, item);
    }
  }

  return Array.from(map.values());
};

// Return the task templates for the specified workflow (matching them by correlation_id).
export const getWorkflowTaskTypes = (workflow, taskTypes, overrideTaskType = null) => {
  if (isEmpty(workflow)) {
    return [];
  }

  // Workflow 'task_types' is actually a list of correlation ids that matches each task template:
  const correlationIds = workflow.task_types;

  // Match by correlation id and inject order:
  const order = workflow.task_types_order;

  let taskTypeList = getTaskTypesInWorkflow(taskTypes, workflow);
  taskTypeList = getLatestPublishedTaskTypes(taskTypeList);
  taskTypeList = taskTypeList.map(
    taskType => ({ ...taskType, order: order[correlationIds.indexOf(taskType.correlation_id)]})
  );

  // Override the field order (used when waiting for an API call to refresh the screen).
  if (overrideTaskType) {
    taskTypeList = updateOverrideItem(taskTypeList, overrideTaskType);
  }

  // Return task templates sorted by the 'order' property:
  return taskTypeList.sort((a, b) => (a.order > b.order) ? 1 : -1);  // eslint-disable-line id-length
};

// From a list of selected task template items (which only contains a value id)
// find them in the taskTypes list in the store and retrieve their correlation ids.
export const getCorrelationIds = (ids, taskTypes) => {
  const values = Object.values(taskTypes).filter(taskType => ids.includes(taskType.id) && taskType.draft === false);
  return getLatestPublishedTaskTypes(values).map(item => item.correlation_id);
};

// If the user has permissions to see more than one activity, display the layout to see more than
// one, else, show the layout for a single activity:
export const isSingleActivityLayout = task => {
  if (task?.activities?.length === 1) {
    return true;
  }

  // If this user belongs to the task owner's agency, show the multiple layout:
  if (isOwnerAgency(task)) {
    return false;
  }

  // Find all activities we have access to:
  const activities = task?.activities?.filter(activity => isAgency(activity) || isAssignee(activity));

  // If we have access to only one activity, use the single layout.
  if (activities?.length === 1) {
    return true;
  }

  // Else he has access to more than one activity, he's probably the task owner, he's in the same
  // agency as the task owner, or (unlikely) he's assigned to more than one activity.
  return false;
};

// This method is just to know if we should render a single agency on the footer
// or display data from all agencies.
export const isSingleFooter = task => {
  // If there's a single activity always show the single version.
  if (task.activities.length === 1) {
    return true;
  }

  // Show the multiple version when:
  if (
    isOwner(task) ||  // I'm the owner.
    task.activities.length === 0 ||  // There are no activities.
    isOwnerAgency(task)  // It's owned by my agency.
  ) {
    return false;
  }

  // Show a single agency when:
  if (
    task.activities.some(activity => isAgency(activity)) ||  // It's assigned to my agency.
    task.activities.some(activity => isAssignee(activity))  // The user is an assignee.
  ) {
    return true;
  }

  // This should not happen, since I'm either the owner, or it's owned/assigned to my agency, or I'm
  // the assignee, but if it happens, show the multiple version.
  return false;
};

// Return true if every single task contains at least one activity where the current user is an assignee.
const allTasksHaveAssignedActivitiesForCurrentUser = tasks => tasks.every(task => task?.activities?.some(activity => isAssignee(activity)));

// Return true if every single task contains at least one activity belonging to the agency the current user is in.
const allTasksHaveActivitiesForCurrentUserAgency = tasks => tasks.every(task => task?.activities?.some(activity => isAgency(activity)));

// With the specified task list generate a title for the list of tasks based on the user's access to them.
export const buildTaskListTitle = tasks => {
  if (tasks?.length === 0) {
    return 'Tasks';
  }

  // Check if the user is the assignee of an activity in each task:
  if (allTasksHaveAssignedActivitiesForCurrentUser(tasks)) {
    // Show in the title we are listing assigned tasks:
    return 'Assigned Tasks';
  }

  // Else check if the all tasks contains activities for the user's agency:
  if (allTasksHaveActivitiesForCurrentUserAgency(tasks)) {
    // Show in the title we are displaying tasks for the user's agency:
    return 'Agency Tasks';
  }

  // Else, just return the default title to list tasks:
  return 'Tasks';
};

// Return true if the responder section of workflow task activities can be edited.
export const isResponderReadOnly = task => {
  const { status_name } = task;
  const isResponder = isAssignee(task);
  const canEdit = canRespondTaskActivity(task);
  const readOnlyStatus = status_name === 'Completed' || status_name === 'Submitted';
  return !canEdit || readOnlyStatus || ((isOwner(task) || isOwnerAgency(task)) && !isResponder);
};

// Common properties for inline edit fields.
export const buildInlineEditCommonProps = (isReadOnly, hasErrors, errors) => ({
  disabled: isReadOnly,
  errorText: hasErrors ? errors.join(', ') : null,
  errorStyle: { ...detailEdit.errors, lineHeight: '1.5rem' },
  // Don't display a floating label, since the 'inline' editing already
  // uses a label outside the component, thus keep 'floatingLabelText'
  // commented:
  // floatingLabelText: label,
  fullWidth: true,
  // Hide default TextField underline:
  underlineShow: false,
  // CSS class to show a border onHover:
  className: `inline-edit-select ${hasErrors ? 'inline-edit-select-error' : ''}`
});

export const getFieldValue = (data, field) => data?.[field] || null;

const getCustomFieldValues = (data, section) => {
  const { field_values } = data?.[section] || data || {};
  return field_values;
};

export const getCustomFieldValue = (data, field_id, section) => {
  return getCustomFieldValues(data, section)?.find(value => value?.field === field_id) || null;
};

export const getCustomFieldValueIndex = (data, field_id, section) => {
  return getCustomFieldValues(data, section)?.findIndex(value => value?.field === field_id) || -1;
};

export const renderStatusItem = (taskStatuses, name, text) => {
  const statusItem = Object.values(taskStatuses).find(item => item.name === name);
  const icon = (
    <span style={{
      backgroundColor: statusItem.color,
      borderRadius: '50%',
      display: 'inline-flex',
      height: '0.5rem',
      width: '0.5rem'
    }} />
  );
  return (
    <AttributeLabel
      contained={false}
      key={statusItem.id}
      text={<span>{icon}&nbsp;{text}&nbsp;</span>}
      toolTip={name}
    />
  );
};

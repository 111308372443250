import React from 'react';
import PropTypes from 'prop-types';
import { capitalize } from 'lodash';
import Actions from '@components/entity/table-header-actions';
import AddButton from '@shared/add-button';
import { pluralize } from '@utils/shared-utils';
import './table-header.scss';

const TableHeader = ({
  actions, button, checked, count, label, onAdd, tooltip
}) => {
  const selected = checked?.filter(item => item);
  return (
    <div styleName="header-container">
      <div styleName="title">
        {selected?.length > 0 && (
          <div styleName="title-selected">
            {selected.length} selected
            <Actions actions={actions} />
          </div>
        )}
        {(!selected || selected.length === 0) && <span>{count}&nbsp;{pluralize(count, capitalize(label))}</span>}
      </div>
      <div styleName="action">
        {button ? button : <AddButton onClick={onAdd} tooltip={tooltip} />}
      </div>
    </div>
  );
};

TableHeader.propTypes = {
  actions: PropTypes.array,
  button: PropTypes.node,
  checked: PropTypes.array,
  count: PropTypes.number,
  label: PropTypes.string,
  onAdd: PropTypes.func,
  tooltip: PropTypes.string
};

export default TableHeader;
